import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid'
import { Col, Modal, Pagination, Row, Switch, Text, useModal } from '@nextui-org/react';
import { Box, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import axios from 'axios'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, FormControlLabel } from '@material-ui/core';
import { CreatePhaseCms, DeletePhaseCms, EditPhaseCms, UpdatePhaseCms, getCmsList } from '../../Api';
import { getAdminToken } from '../../Helper/Token';
import Form from 'react-bootstrap/Form';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SuperBalls } from '@uiball/loaders';

const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

function NextPhase() {

    const { setVisible: setContentEditModalVisible, bindings: addContentModel } = useModal();
    const { setVisible: setContentPreEditModalVisible, bindings: editContentModalBindings } = useModal();

    const openAdd = () => setContentEditModalVisible(true)
    const closeAdd = () => setContentEditModalVisible(false)

    const openEdit = () => setContentPreEditModalVisible(true)
    const closeEdit = (e) => {
        e.preventDefault();
        setContentPreEditModalVisible(false)
        setEditData({})
    }
    const columns = [
        { field: 'id', headerName: 'Id', width: 40 },
        { field: 'Title', headerName: 'Name', width: 150, },
        { field: 'Discription', headerName: 'Discription', width: 150, },
        // { field: 'EndsTime', headerName: 'Popup End Time', width: 130, },
        { field: 'formattedDateTime', headerName: 'Date', width: 200, },
        {
            field: "Status", headerName: "Status", sortable: false, width: 100,
            renderCell: (prevents) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} key={prevents.row._id}>
                        <Switch defaultSelected color="success" 
                        checked={prevents.row.isActive === true} 
                        onChange={(e) => handleEnableDisable(e, prevents.row.flag, prevents.row._id)}
                        disabled={buttonState[prevents.row._id] === true}
                        >Success</Switch>
                    </div>
                );
            }
        },
        {
            field: "actions", headerName: "Action", sortable: false, width: 200,
            renderCell: (prevents) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <MatEdit index={prevents.row} />
                        <MatDelete index={prevents.row} />
                    </div>
                );
            }
        }
    ];

    // edit
    const MatEdit = ({ index }) => {

        return (
            <FormControlLabel
                control={
                    <IconButton aria-label="add an alarm" className='dyFlextIcon' onClick={(e) => handleEditClick(e, index)}>
                        <ModeEditOutlineOutlinedIcon />
                    </IconButton>
                }
            />
        );
    };

    // Enable Disable
    const [buttonState, setButtonState] = useState({});

    const handleEnableDisable = (e, status, id) => {
        let newStatus = {
            flag: 0,
        };

        if (status === 1) {
            newStatus.flag = 3;
        } else if (status === 3) {
            newStatus.flag = 1;
        }

        const updatedButtonState = { ...buttonState };
        Object.keys(updatedButtonState).forEach((buttonId) => {
            updatedButtonState[buttonId] = true;
        });
        updatedButtonState[id] = false;
        setButtonState(updatedButtonState);

        getAdminToken();
        axios.put(`${UpdatePhaseCms}/${id}`, newStatus)
            .then((res) => {
                toast.success(res.data.message, toastStyle);
                fetchContentList()
            })
            .catch((err) => {
                toast.error(err.response.data.message, toastStyle);
            })
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = (index) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);


    const [pageState, setPageState] = useState({
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10
    })

    const [newData, setNewData] = useState({
        Title: "",
        Discription: "",
        EndsTime: "",
        AdditionalInfo: "",
        ButtonLink: "",
        ButtonTitle: "",
    })
    const handleChange = (e, data) => {
        const { name, value } = e.target;
        setNewData({
            ...newData,
            [name]: value
        })
        if (data === "date") {
            setNewData({
                ...newData,
                [name]: new Date(value).toISOString()
            })
        }
    }

    // Create Content 
    const handleCreateContent = (e) => {
        e.preventDefault();
        getAdminToken();
        axios.post(CreatePhaseCms, newData)
            .then((res) => {
                closeAdd();
                toast.success(res.data.message, toastStyle);
                fetchContentList();
            })
            .catch((err) => {
                toast.error(err.response.data.message, toastStyle);
            })
    }

    // Edit Content
    const [editData, setEditData] = useState({})
    const handleEditChange = (e, data) => {
        const { name, value } = e.target;
        setEditData({
            ...editData,
            [name]: value
        })

        if (data === "dateE") {
            setEditData({
                ...editData,
                [name]: new Date(value).toISOString(),
                endTime: value
            })
        }
        if (data === "desc") {
            setEditData({
                ...editData,
                [name]: value,
                Description: value
            })
        }
    }

    const handleEditClick = (e, data) => {
        const date = new Date(data.EndsTime);
        const EndTime = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

        setEditData({
            ...editData,
            Title: data.Title,
            Discription: data.Discription,
            EndsTime: data.EndsTime,
            endTime: EndTime,
            ButtonTitle: data.ButtonTitle,
            ButtonLink: data.ButtonLink,
            AdditionalInfo: data.AdditionalInfo,
            _id: data._id
        });

        openEdit();
    }

    const handleEditContent = (e) => {
        e.preventDefault();
        getAdminToken();
        axios.put(`${EditPhaseCms}/${editData._id}`, editData)
            .then((res) => {
                closeEdit(e);
                fetchContentList();
                toast.success(res.data.message, toastStyle);
            })
            .catch((err) => {
                toast.error(err.response.data.message, toastStyle);
            })
    }

    // Delete

    function MatDelete({ index }) {
        const { setVisible: setProductDeleteModalVisible, bindings: productDeleteModalBindings } = useModal();
        const closeProductDeleteModel = () => {
            setProductDeleteModalVisible(false)
        }

        const handleDeleteProduct = (e) => {
            getAdminToken();
            axios.delete(`${DeletePhaseCms}/${index._id}`)
                .then((res) => {
                    toast.success(res.data.message, toastStyle);
                    fetchContentList()
                }).catch((err) => {
                    toast.error(err.response.data.message, toastStyle);
                })
        }
        return <FormControlLabel
            control={<>
                <div className='ActionButtons'>
                    <IconButton aria-label="add an alarm" onClick={() => setProductDeleteModalVisible(true)} className='dyFlextIcon'>
                        <DeleteOutlineIcon />
                    </IconButton>

                    <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...productDeleteModalBindings} >
                        <Modal.Header justify='start'>
                            <Text id="modal-title" size={18}>
                                Are You <Text b id="modal-title" size={18}> Sure!</Text>
                            </Text>
                        </Modal.Header>
                        <Modal.Body className='deleteModel'>
                            <Row className="mb-3">
                                <h2>Want to Delete <strong style={{ color: "var(--peimary)" }}>{index?.Title}</strong> Content?</h2>
                                <p>Do you really want to delete these content? </p>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button auto flat className='modelClose' onClick={() => closeProductDeleteModel()}>
                                No, Keep it
                            </Button>
                            <Button auto className='modelSubmit common-btn' onClick={(e) => handleDeleteProduct(e)}>
                                Yes, Delete it
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>} />;
    }

    // Get Content List
    const [rows, setRows] = useState([]);
    
    const fetchContentList = () => {
        getAdminToken();
        axios.get(`${getCmsList}`)
            .then((res) => {
                const json = res.data.result;
                setPageState((prevState) => ({ ...prevState, total: json.totalDocs, totalPages: json.totalPages }));


                const dateObject = new Date(res.data.result.docs[0].EndsTime);
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
                const day = dateObject.getDate();
                const monthIndex = dateObject.getMonth();
                const year = dateObject.getFullYear();
                const hours = dateObject.getHours();
                const minutes = dateObject.getMinutes();
                const amPm = hours >= 12 ? 'PM' : 'AM';
                const hours12 = hours % 12 || 12; // Convert to 12-hour format

                res.data.result.docs.map((ele, i) => {
                    ele.id = i + 1;
                    ele.formattedDateTime = `${day} ${months[monthIndex]}, ${year} ${hours12}:${minutes < 10 ? '0' : ''}${minutes}${amPm}`;
                });
                setRows(res.data.result.docs)
            })
    }
    useEffect(() => {
        fetchContentList();
    }, [])


    const handlePageChange = (selectedPage) => {
        setPageState((prevState) => ({ ...prevState, page: selectedPage }));
    };
    const ResultInfo = () => {
        const start = (pageState.page - 1) * pageState.pageSize + 1;
        const end = Math.min(start + pageState.pageSize - 1, pageState.total);

        return (
            <div className="result-info">
                Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Tickets
            </div>
        );
    };




    return (
        <section className='adminDashboard support_lists'>
            <div className="dashboard-right-header fr05s-usr-quotsn05s">
                <div className="left-text">
                    <h4>CMS</h4>
                    <p className="m-0">Here You can manage your <b>Phase Change</b> Content</p>
                </div>
                <div className="export_sheet">
                    <Button className='usdercmntbtn common-btn' onClick={openAdd} ><AddIcon />Create Content</Button>
                </div>
            </div>

            <div className='datagridtableusqot00qat'>
                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        autoHeight {...rows}
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        pagination={false}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <SuperBalls size={45} speed={1.4} color="var(--primary)" />
                                </Stack>
                            )
                        }}
                    />
                </Box>
                <div className='pagination-block'>
                    <ResultInfo />
                    <Pagination
                        loop
                        color="success"
                        total={pageState.totalPages}
                        initialPage={1}
                        onChange={(page) => handlePageChange(page)}
                        currentPage={pageState.page}
                    />
                </div>
            </div>

            {/* Create Content */}
            <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...addContentModel} >
                <Modal.Header justify='start'>
                    <Text id="modal-title" size={18}>
                        Create Website CMS
                    </Text>
                </Modal.Header>
                <Modal.Body>

                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                            <Form.Label>Name</Form.Label>
                            <Form.Control className='w-100' type="text" placeholder="Phase Name" name="Title" onChange={(e) => handleChange(e)} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} controlId="form-control-product">
                            <Form.Label>Set Popup End Time</Form.Label>
                            <Form.Control type="datetime-local" placeholder="Set Popup End Time" name="EndsTime" onChange={(e) => handleChange(e, "date")} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" placeholder="Description" name='Discription' rows={3} onChange={(e) => handleChange(e)} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                            <Form.Label>Additional Info</Form.Label>
                            <Form.Control className='w-100' type="text" placeholder="Additional Info" name="AdditionalInfo" onChange={(e) => handleChange(e)} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                            <Form.Label>Button Name</Form.Label>
                            <Form.Control className='w-100' type="text" placeholder="Enter Button Name" name="ButtonTitle" onChange={(e) => handleChange(e)} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                            <Form.Label>Button URL</Form.Label>
                            <Form.Control className='w-100' type="text" placeholder="Enter Button URL" name="ButtonLink" onChange={(e) => handleChange(e)} />
                        </Form.Group>
                    </Row>


                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat className='modelClose' onClick={() => closeAdd()}>
                        Cancel
                    </Button>
                    <Button auto className='modelSubmit common-btn' onClick={(e) => handleCreateContent(e)} >
                        Create Content
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Content */}
            <Modal scroll width="600px" closeButton aria-labelledby="modal-title" aria-describedby="modal-description" {...editContentModalBindings} >
                <Modal.Header justify='start'>
                    <Text id="modal-title" size={18}>
                        Create Website CMS
                    </Text>
                </Modal.Header>
                <Modal.Body>

                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                            <Form.Label>Name</Form.Label>
                            <Form.Control className='w-100' type="text" placeholder="Phase Name" name="Title" value={editData.Title} onChange={(e) => handleEditChange(e)} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} controlId="form-control-product">
                            <Form.Label>Set Popup End Time</Form.Label>
                            <Form.Control type="datetime-local" placeholder="Set Popup End Time" value={editData.endTime} name="EndsTime" onChange={(e) => handleEditChange(e, "dateE")} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" placeholder="Description" name='Discription' value={editData.Discription} rows={3} onChange={(e) => handleEditChange(e, "desc")} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                            <Form.Label>Additional Info</Form.Label>
                            <Form.Control className='w-100' type="text" placeholder="Additional Info" name="AdditionalInfo" value={editData.AdditionalInfo} onChange={(e) => handleEditChange(e)} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                            <Form.Label>Button Name</Form.Label>
                            <Form.Control className='w-100' type="text" placeholder="Enter Button Name" name="ButtonTitle" value={editData.ButtonTitle} onChange={(e) => handleEditChange(e)} />
                        </Form.Group>
                    </Row>
                    <Row className="row mb-3 gap-3">
                        <Form.Group as={Col} className="form-control-product" controlId="formBasicImage">
                            <Form.Label>Button URL</Form.Label>
                            <Form.Control className='w-100' type="text" placeholder="Enter Button URL" name="ButtonLink" value={editData.ButtonLink} onChange={(e) => handleEditChange(e)} />
                        </Form.Group>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button auto flat className='modelClose' onClick={(e) => closeEdit(e)}>
                        Cancel
                    </Button>
                    <Button auto className='modelSubmit common-btn' onClick={(e) => handleEditContent(e)} >
                        Edit Content
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </section>


    )
}

export default NextPhase