import React, { useEffect, useState } from 'react'
import "./Dashboard.css"
import { getAdminDetails, getDashboardStats, getOrderList, getPhaseList } from '../../Api'
import axios from 'axios'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { getAdminToken } from '../../Helper/Token'
import { BsTicketPerforated } from 'react-icons/bs'
import { Button, Form } from 'react-bootstrap'
import { formatNumberWithCommas } from '../../Helper/validation'
import ApexChart from './ApexChart '
import OnlineStats from './OnlineStats';
import PreEventStats from './PreEventStats';
import { NavLink } from 'react-router-dom';
import OnGoingStats from './OnGoingStats';


function Dashboard() {

  // Get User Details
  const [adminDetails, setAdminDetails] = useState({});
  const fetchAdminDetails = () => {
    getAdminToken();
    axios.patch(getAdminDetails)
      .then((res) => {
        setAdminDetails(res.data.admin)
      })
  }
  useEffect(() => {
    fetchAdminDetails();
  }, [])

  // Get Order List
  const [stats, setStates] = useState({
    totalTickets: 0,
    total24HSale: 0,
    totalSale: 0,
  })
  const [totalVisitors, setTotalVisitors] = useState(0)

  const fetchOrderList = () => {
    getAdminToken();

    axios.get(`${getOrderList}?limit=5`)
      .then((res) => {
        res.data.result.docs.map((ele, i) => {
          ele.srno = (res.data.result.page * 10) + (i + 1) - 10
          ele.id = i + 1;

          ele.UserName = ele.UserInfo?.name
          ele.UserEmail = ele.UserInfo?.email
          const timestamp = ele.createdAt;
          const date = new Date(timestamp);
          ele.ISTDate = date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });

          if (ele.PaymentType.PaymenttypeNumber == 1) {
            ele.PaymentType = "Online"
          } else if (ele.PaymentType == 2) {
            ele.PaymentType = "Cash"
          }
        });
        setStates({
          ...stats,
          totalTickets: res.data.result.totalDocs,
          totalSale: res.data.result.GrandTotal,
          total24HSale: res.data.result.last24HourSaleAmount
        })
      })
  }
  useEffect(() => {
    fetchOrderList();
  }, [])

  // Phase Options
  const [allPhase, setAllPhase] = useState([])
  const fetchAllPhase = () => {
    axios.get(getPhaseList)
      .then((res) => {
        res.data.result.map((ele) => {
          ele.value = ele._id;
          ele.label = ele.PhaseName
        })
        setAllPhase(res.data.result)
      })
  }
  useEffect(() => {
    fetchAllPhase();
  }, [])

  // Get Selected Phase
  const [phaseValue, setPhaseValue] = useState("");
  const handlePhase = (e) => {
    setPhaseValue(e.target.value)
  }


  // Get Dashboard Stats
  const [data, setData] = useState([]);

  const fetchDashboardStats = () => {
    const apiURL = `${getDashboardStats}?phaseId=${phaseValue}`;

    axios.get(apiURL)
      .then((res) => {
        const newData = [["Tickets", "Total Tickets"]];
        let cumulativeTotal = 0;
        for (let i = 0; i < res.data.result?.length; i++) {
          const { day, totalPrice, totalPurchasedTickets } = res.data.result[i];
          if (day === "Season Pass") {
            cumulativeTotal += (totalPurchasedTickets * 9);
          } else {
            cumulativeTotal += totalPurchasedTickets;
          }

          newData.push([day, totalPurchasedTickets]);
        }
        setTotalVisitors(cumulativeTotal)
        setData(newData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchDashboardStats();
  }, [phaseValue]);


  // get Todays Sale
  const [todaysSale, setTodaysSale] = useState(0)
  const fetchTodatSAle = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const nextDay = new Date(today.getTime() + (24 * 60 * 60 * 1000));

    axios.get(`${getOrderList}?dateStart=${today.toISOString()}&dateEnd=${nextDay.toISOString()}`)
      .then((res) => {
        axios.get(`${getOrderList}?dateStart=${today.toISOString()}&dateEnd=${nextDay.toISOString()}&limit=${res.data.result.totalDocs}`)
          .then((resp) => {
            let totalSaleToday = 0;
            resp.data.result.docs.map((ele) => {
              totalSaleToday += ele.OrderTotal
            })
            setTodaysSale(totalSaleToday)
          })
      })
  }
  useEffect(() => {
    fetchTodatSAle()
  }, [])

  return (
    <div className='Admin_Dashboard userdashboard'>
      <div className="dashboard-right-header">
        <div className="left-text">
          <h4>Hello, {adminDetails.name} </h4>
          <p className="m-0">Here is your sales analytics</p>
        </div>
        <div className="export_sheet">
          <NavLink to="/admin/dashboard/each-day-sale">
            <Button className='common-btn m-0' >Each Day Sale</Button>
          </NavLink>
        </div>
      </div>
      <div className="userdash-row">
        <div className="dashboard-stats-box ">
          <div className="single-feature-box sfb01">
            <div className="single-feature-icon">
              <BsTicketPerforated />
            </div>
            <div className="single-feature-data">
              <h6>{stats.totalTickets}</h6>
              <p>Total Orders</p>
            </div>
          </div>
        </div>
        <div className="dashboard-stats-box ">
          <div className="single-feature-box sfb01">
            <div className="single-feature-icon">
              <BsTicketPerforated />
            </div>
            <div className="single-feature-data">
              <h6>{totalVisitors}</h6>
              <p>Total Visitors</p>
            </div>
          </div>
        </div>
        <div className="dashboard-stats-box">
          <div className="single-feature-box bg-danger-50 ">
            <div className="single-feature-icon">
              <CurrencyRupeeIcon />
            </div>
            <div className="single-feature-data">
              <h6>{formatNumberWithCommas(stats.totalSale)}</h6>
              <p>Total Sale</p>
            </div>
          </div>
        </div>
        <div className="dashboard-stats-box">
          <div className="single-feature-box ">
            <div className="single-feature-icon">
              <CurrencyRupeeIcon />
            </div>
            <div className="single-feature-data ">
              <h6>{formatNumberWithCommas(todaysSale)}</h6>
              <p>Today's Sale</p>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-subheading dashboard">
        <div className="left-text">
          <h5>Each Day Pass</h5>
        </div>
      </div>
      <div>
        <div className='phaseList'>
          <Form.Check
            key="all"
            type="radio"
            id={`phase-radio`}
            label="All"
            value=""
            checked={phaseValue === ""}
            onChange={(e) => handlePhase(e)}
          />
          {allPhase.map((ele) => (
            <Form.Check
              key={ele._id}
              type="radio"
              id={`phase-radio-${ele._id}`}
              label={ele.PhaseName}
              value={ele._id}
              checked={phaseValue === ele._id}
              onChange={(e) => handlePhase(e)}
            />
          ))}
        </div>
        <ApexChart data={data} />
        
        <div className='otherStats'>
          <div className="dashboard-subheading main">
            <div className="left-text">
              <h4>Processed Ordres</h4>
            </div>
            <div className="export_sheet">
              <NavLink to="/admin/orders">
                <Button className='common-btn m-0' >View All</Button>
              </NavLink>
            </div>
          </div>
          <OnlineStats />
        </div>

        <div className='otherStats'>
          <div className="dashboard-subheading main">
            <div className="left-text">
              <h4>Pre Event Ordres</h4>
            </div>
            <div className="export_sheet">
              <NavLink to="/admin/pre-event-orders">
                <Button className='common-btn m-0' >View All</Button>
              </NavLink>
            </div>
          </div>
          <PreEventStats />
        </div>

        <div className='otherStats'>
          <div className="dashboard-subheading main">
            <div className="left-text">
              <h4>On Going Event Ordres</h4>
            </div>
            <div className="export_sheet">
              <NavLink to="/admin/on-going-event-orders">
                <Button className='common-btn m-0' >View All</Button>
              </NavLink>
            </div>
          </div>
          <OnGoingStats />
        </div>
      </div>
    </div>
  )
}

export default Dashboard