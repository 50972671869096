import React from 'react'
import "./SiteFooter.css"
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { BsTicketPerforated } from 'react-icons/bs'
import Logo from '../../Images/ahuraicon.png'
import AT from '../../Images/AT.png'
import { NavLink } from 'react-router-dom'
import { BsFillEnvelopeFill } from 'react-icons/bs'
import PhoneIcon from '@mui/icons-material/Phone';

function SiteFooter() {

  const currentYear = new Date().getFullYear();

  return (

    <>

      <div className='Footer desktopOnlyFooter'>
        <div className="container">
          <div className="footer-top">
            <div className='left'>
              <h2>GET <span> IN touch</span></h2>
            </div>
            <div className='right'>
              <div className='contact'>
                <NavLink to="tel:+918780755209">
                  <PhoneIcon />
                  <div className='icon_box_bt'>
                    +91-87807 55209
                  </div>
                </NavLink>
                <NavLink to="mailto:commoneventsplanningteam@gmail.com">
                  <BsFillEnvelopeFill />
                  <div className='icon_box_bt'>
                    commoneventsplanningteam@gmail.com
                  </div>
                </NavLink>
              </div>
              <div className='social-media' >
                <ul>
                  <li><a href="https://www.instagram.com/mandli_garba/?hl=en" target='_blank'><AiFillInstagram /></a></li>
                  <li><a href="https://www.youtube.com/@Mandli_Garba" target='_blank'><AiFillYoutube /></a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footerMain">
            <div className='row'>
              <div className="title">
                <div className='footerBuyNow'>
                  <h2>Ahmedabad's most <br />awaited <span>Garba</span> experience!</h2>
                  <NavLink className="common-btn cst-font-size" to="/buy-now" ><BsTicketPerforated /><span>Book Passes</span></NavLink>
                </div>
              </div>
              <div className="links">
                <div className="title">
                  <h3>useful links</h3>
                  <ul>
                    <li><NavLink to="/about">About</NavLink></li>
                    <li><NavLink to="/gallery">Gallery</NavLink></li>
                    <li><NavLink to="/support">Contact</NavLink></li>
                  </ul>
                </div>
              </div>
              <div className="links">
                <div className="title">
                  <h3>Other Links</h3>
                  <ul>
                    <li hidden><NavLink to="/support">Contact</NavLink></li>
                    <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                    <li><NavLink to="/terms-and-conditions">Terms of Service</NavLink></li>
                    <li><NavLink to="/refund-and-cancellation">Refund & Cancellation</NavLink></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='Site_footer'>
          <div className='container'>
            <div className='coppy_rights'>
              <h1>&copy; {currentYear} Mandli</h1>
            </div>
            <div className="design_devlop_At">
              <NavLink to="https://ahuratechnosoft.com" >
                <h1>Designed and Developed by</h1>
                <img src={AT} alt='Designed and Developed by AT' />
              </NavLink>
            </div>
          </div>
        </div>
      </div>


      <div className='Footer mobileOnlyFooter'>
        <div className='container'>
          <div id="ft_blocks">
            <div className='title_ft'>
              <h2>GET <span> IN touch</span></h2>
            </div>
            <div className='icon-box'>
              <div className='ft_titile title_socials'>
                <p>Support</p>
              </div>
              <NavLink to="tel:+918780755209">
              <PhoneIcon/>
                <div className='icon_box_bt'>
                  +91-87807 55209
                </div>
              </NavLink>
              <NavLink to="mailto:commoneventsplanningteam@gmail.com">
                <BsFillEnvelopeFill/>
                <div className='icon_box_bt'>
                  commoneventsplanningteam@gmail.com
                </div>
              </NavLink>
            </div>
            <div className='hr_line two'></div>
            <div className='social_icons'>
              <div className='follows ft_titile title_socials'>
                <p>Follow us :</p>
              </div>
              <div className='social-media' >
                <ul>
                  <li><a href="https://www.instagram.com/mandli_garba/?hl=en" target='_blank'><AiFillInstagram /></a></li>
                  <li><a href="https://www.youtube.com/@Mandli_Garba" target='_blank'><AiFillYoutube /></a></li>
                </ul>
              </div>
            </div>
            <div className='hr_line one'></div>
            <div className='sptr_pages'>
              <div className='ft_titile title_socials'>
                <p>Other Links</p>
              </div>
              <NavLink to="/support">Support</NavLink>
              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              <NavLink to="/terms-and-conditions">Terms of Service</NavLink>
              <NavLink to="/refund-and-cancellation">Refund & Cancellation</NavLink>
            </div>

            <div className='sptr_pages mobile'>
              <div className='spacer'>
                <NavLink to="/support">Support</NavLink>
                <div className='line_spacer'></div>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </div>
              <div className='spacer two'>
                <NavLink to="/terms-and-conditions">Terms of Service</NavLink>
                <div className='line_spacer'></div>
                <NavLink to="/refund-and-cancellation">Refund & Cancellation</NavLink>
              </div>
            </div>

          </div>
        </div>

        <div className='Site_footer'>
          <div className='container'>
            <div className='coppy_rights'>
              <h1>&copy; {currentYear} Mandli</h1>
            </div>
            <div className="design_devlop_At">
              <NavLink to="https://ahuratechnosoft.com" >
                <h1>Designed and Developed by</h1>
                <img src={AT} alt='Designed and Developed by AT' />
              </NavLink>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

export default SiteFooter