import React, { useEffect } from 'react'
import './About.css'
import img1 from '../../Images/Mandli/shruti.jpeg'
import img2 from '../../Images/Mandli/kavan.jpg'
import img3 from '../../Images/Mandli/Sagar-Vadher.jpeg'
import img4 from '../../Images/Mandli/Maharshi-Patel.jpeg'
import img5 from '../../Images/Mandli/Karan-Makwana.jpg'
import Aboutbnr from '../../Images/Mandli/CEPT-about.png'

function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section className='About-Us buyNow'>

            <div className='inner-banner'>
                <div className="container">
                    <h2>about us</h2>
                    <div className='about_blocks'>
                        <h1><span>C</span>OMMON <span>E</span>VENTS <span>P</span>LANNING <span>T</span>EAM
                        </h1>
                    </div>
                </div>
            </div>

            <div className="main About">
                <div className="container">

                    <div className='about_discriptions'>
                        <p>We are passionate CEPT University alumni who have reimagined the essence of Navratri in Gujarat. Our mission is simple yet bold: to infuse the vibrant spirit of sheri-style garba with the power of design.</p>
                        <p>As former students of CEPT University, we cherished Navratri in a unique way, merging design sensibilities with the rustic charm of sheri garba. This fusion has been our treasured legacy, and we are thrilled to share it with you through Mandli. We are more than just event organizers; we are creators of an experience. Our goal is to craft an unforgettable nine-day festival that transcends the ordinary.</p>
                        <p>Join us on this extraordinary journey as we redefine Navratri in Gujarat!</p>
                    </div>

                    <div className='about_discriptions Mobile'>
                        <p>
                            <img className='about_baner_img' src={Aboutbnr} alt="" />
                            We are passionate CEPT University alumni who have reimagined the essence of Navratri in Gujarat. Our mission is simple yet bold: to infuse the vibrant spirit of sheri-style garba with the power of design.</p>
                        <p>As former students of CEPT University, we cherished Navratri in a unique way, merging design sensibilities with the rustic charm of sheri garba. This fusion has been our treasured legacy, and we are thrilled to share it with you through Mandli. We are more than just event organizers; we are creators of an experience. Our goal is to craft an unforgettable nine-day festival that transcends the ordinary.</p>
                        <p>Join us on this extraordinary journey as we redefine Navratri in Gujarat!</p>
                    </div>

                    <div className='About_team_block'>
                        <div className='team_mambers'>
                            <img src={img1} alt="" />
                            <h1>Drashti Patel</h1>
                            <p>CEPT SID ‘16</p>
                        </div>

                        <div className='team_mambers'>
                            <img src={img2} alt="" />
                            <h1>Kavan Berawala</h1>
                            <p>CEPT BARCH ‘14</p>
                        </div>


                        <div className='team_mambers'>
                            <img src={img3} alt="" />
                            <h1>Sagar Vadher</h1>
                            <p>CEPT BARCH ‘11</p>
                        </div>

                        <div className='team_mambers'>
                            <img src={img4} alt="" />
                            <h1>Maharshi Patel</h1>
                            <p>CEPT SBST ‘20</p>
                        </div>

                        <div className='team_mambers'>
                            <img src={img5} alt="" />
                            <h1>Karan Makwana</h1>
                            <p>CEPT BARCH ‘12</p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default About