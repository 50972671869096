import React, { useEffect } from 'react'
import "./ForgotPass.css"
import Logo from '../../Images/Logo.png'
import GoogleLogo from '../../Images/google.png'
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { getForgotPassword, getOpt } from '../../Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import OtpInput from 'react-otp-input';
import { getVerifyOtp } from '../../Api';
import { AiOutlineEye } from 'react-icons/ai'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { Crypt } from 'hybrid-crypto-js';
import { useNavigate } from "react-router-dom";
import LogoImg from '../../Images/Quotastic.png'
import rotate_image from '../../Images/rotate-image.png';

const toastStyle = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
}

function ForgotPass() {
  var crypt = new Crypt();
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState('');

  const [showOtpPanel, setShowOtpPanel] = useState(false)
  const [showNewPasswordPanel, setShowNewPasswordPanel] = useState(false)
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);


  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSendEmail = (e) => {
    e.preventDefault();
    const emailInfo = new FormData()
    emailInfo.append('Email', email)

    if (email != "") {
      axios.post(getOpt, emailInfo)
        .then((res) => {
          toast.success(res.data.message, toastStyle);
          setShowOtpPanel(true)
          setTimer(30);
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })
    } else {
      toast.error("Email is required", toastStyle);
    }
  }

  // Verify Otp
  const handleVerifyOtp = (e) => {
    e.preventDefault();
    const otpInfo = new FormData()
    otpInfo.append('email', email)
    otpInfo.append('code', otp)

    if (otp) {
      axios.post(getVerifyOtp, otpInfo)
        .then((res) => {
          toast.success(res.data.message, toastStyle);
          setShowNewPasswordPanel(true)
        })
        .catch((err) => {
          toast.error(err.response.data.message, toastStyle);
        })
    } else {
      toast.error("Entar OTP", toastStyle);
    }

  }

  const [Password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Show or Hide Password
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // get enterd password
  const handlePassword = (e) => {
    const { name, value } = e.target;
    name === 'Password' ? setPassword(value) : setConfirmPassword(value);
    if (name === 'Password' && confirmPassword && value !== confirmPassword) {
      setError(true);
    } else if (name === 'confirmPassword' && Password && value !== Password) {
      setError(true);
    } else {
      setError(false);
    }
  }

  const handleChangePassword = (e) => {
    e.preventDefault();
    // Encrypt enterd password
    let encryptedPassword = crypt.encrypt(process.env.REACT_APP_PUBLIC_KEY, confirmPassword);

    const passwordData = new FormData();
    passwordData.append('code', otp);
    passwordData.append('newPassword', encryptedPassword);

    axios.patch(`${getForgotPassword}/${email}`, passwordData)
      .then((res) => {
        toast.success(res.data.message, toastStyle);
        navigate("/login")
      })
      .catch((err) => {
        toast.error(err.response.data.message, toastStyle);
      })
  }

  return (
    <div className='ForgotPass Login mandli-bg'>
      <div className='rotate-image'>
        <img src={rotate_image} alt="" />
      </div>
      <div className="header-top-area-shape"></div>
      <div className='login-block'>
        <div className='logo'>
          <div id="nav">
            <h1>mand<span>li<sup>&reg;</sup></span></h1>
          </div>
        </div>
        <div className='at-form'>
          {
            !showOtpPanel && !showNewPasswordPanel ?
              <div className='login-form'>
                <h3>Recover your password</h3>
                <Form>
                  <Form.Group className="form-control" controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Enter email" name="email" onChange={(e) => handleChange(e)} />
                  </Form.Group>
                  <Button variant="contained" type="submit" className='primaryBtn common-btn' onClick={(e) => handleSendEmail(e)}>
                    Send me Email
                  </Button>
                  <p className='signup-line'>Back to <NavLink to="/login" >Login</NavLink></p>
                </Form>
              </div> : ""
          }
          {
            showOtpPanel && !showNewPasswordPanel ?
              <div className='login-form'>
                <h3>OTP Verification</h3>
                <p>Enter your verification code that we sent you thought your Email id</p>
                <Form className='otp-field'>
                  <OtpInput
                    className="otp-field"
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  <p className='resend-btn'>
                    {timer > 0
                      ? `Resend code in ${timer} seconds`
                      : <><span onClick={(e) => handleSendEmail(e)}>Resend</span> OTP</>}
                  </p>
                  <Button variant="contained" type="submit" className='primaryBtn common-btn' onClick={(e) => handleVerifyOtp(e)}>
                    Confirm
                  </Button>
                  <p className='signup-line'>Back to <NavLink to='/login' >Login</NavLink></p>
                </Form>
              </div> : ""
          }
          {
            showNewPasswordPanel ?
              <div className='login-form'>
                <h3>OTP Verification</h3>
                <p>Enter your verification code that we sent you thought your Email id</p>
                <Form className='otp-field'>
                  <Form.Group className={error ? "form-control password error" : "form-control password"} controlId="formBasicPassword">
                    <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" name="Password" onChange={(e) => handlePassword(e)} />
                    <Button variant="outline-secondary" onClick={toggleShowPassword}>{!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</Button>
                  </Form.Group>

                  <Form.Group className={error ? "form-control error" : "form-control"} controlId="formBasicPassword">
                    <Form.Control type="password" placeholder="Confirm Password" name="confirmPassword" onChange={(e) => handlePassword(e)} />
                    {error ? <p>Password Does Not Match</p> : ""}
                  </Form.Group>

                  <Button variant="contained" type="submit" className='primaryBtn common-btn' onClick={(e) => handleChangePassword(e)}>
                    Verify
                  </Button>
                  <p className='signup-line'>Back to <NavLink to="/login" >Login</NavLink></p>
                </Form>
              </div> : ""
          }
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default ForgotPass