import React, { useEffect, useState } from 'react'
import './Maintenance.css'
import wheel from '../../Images/wheel.png'
import { getWebMaintenance } from '../../Api'
import axios from 'axios'

function Maintenance() {
    // Under Maintenance
    const [maintenance, setMaintenance] = useState(false)
    const fetchMaintenance = () => {
        axios.get(getWebMaintenance)
            .then((res) => {
                res.data.result.docs.map((ele) => {
                    if (ele.flag === 1) {
                        setMaintenance(ele)
                    }
                })
            })
    }
    useEffect(() => {
        fetchMaintenance()
    }, [])

    // Time Count Down
    const endDate = new Date(maintenance.EndsTime).getTime();
    const [timeLeft, setTimeLeft] = useState({})
    function updateCountdown() {
        const now = new Date().getTime();
        const timeLeft = endDate - now;

        if (timeLeft <= 0) {
            return;
        }

        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setTimeLeft({
            d: days,
            h: hours,
            m: minutes,
            s: seconds
        })
    }
    setInterval(() => {
        if (maintenance.EndsTime) {
            updateCountdown()
        }
    }, 1000);

    return (
        <div className='comming-soon maintenance'>
            <div class="mandli">
                <h1>Mand<span>li</span></h1>
                <p>{maintenance.Title}</p>
                <div className='Timer_block'>
                    <p>
                        {maintenance.Discription} <br />
                    </p>
                    <p className='timer'>{Object.keys(timeLeft).map((unit) => (
                        timeLeft[unit] > 0 && (
                            <div className="flip" key={unit}>
                                <div className="top">{`${timeLeft[unit] < 10 ? `0${timeLeft[unit]}` : timeLeft[unit]}`}<span>{unit}</span></div>
                            </div>
                        )
                    ))}</p>
                </div>
            </div>
            <div class="wheel">
                <img src={wheel} alt="Animation" />
            </div>
        </div>
    )
}

export default Maintenance