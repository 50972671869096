import React, { useEffect } from 'react'
import './Support.css'
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close';
import { getToken } from '../../Helper/Token';
import { getCreateSupport, getPatchUserDetails, getUserDetails } from '../../Api';
import Phone from '@mui/icons-material/Phone';
import { validateRequiredFields } from '../../Helper/validation';


const toastStyle = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
}

const style = {
    position: 'absolute',
    top: '50%',
    right: '0',
    left: '50%',
    height: '400px',
    bottom: '0',
    width: '35%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    BorderRadius: '5px'
    // p: '1.5rem',
};

// const CheckIcon = () => (
//     <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path
//             d="M7 25L27.3077 44L58.5 7"
//             stroke="white"
//             strokeWidth="13"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//         />
//     </svg>
// );

function Support() {

    // Get User Details
    const [userDetails, setUserDetails] = useState({})
    const fetchUserDetails = () => {
        getToken();
        axios.patch(getPatchUserDetails)
            .then((res) => {
                axios.get(`${getUserDetails}/${res.data.user._id}`)
                    .then((res) => {
                        setUserDetails(res.data.result)
                    })
            })
    }
    useEffect(() => {
        fetchUserDetails()
    }, [])

    // SetUserDetails
    useEffect(() => {
        if (userDetails) {
            SetSupoortTicket({
                ...suportTicket,
                Name: userDetails?.name,
                Email: userDetails?.email,
                Phone: userDetails?.phone
            })
        }
    }, [userDetails])


    // Popup
    const [showPopup, setShowPopup] = useState(false);
    const handleOpenShowPopup = () => setShowPopup(true);
    const handleCloseShowPopup = () => {
        setShowPopup(false)
        SetSupoortTicket({
            ...suportTicket,
            Name: "",
            Email: "",
            Issue: "",
            Phone: "",
            Description: "",
            attachment: ""
        })
        setErrShow(false)
    };

    // Variables
    const [suportTicket, SetSupoortTicket] = useState({
        Name: "",
        Email: "",
        Issue: "",
        Phone: "",
        Description: "",
        attachment: ""
    })

    // Set Data
    const handleChange = (e, data) => {
        SetSupoortTicket({
            ...suportTicket,
            [e.target.name]: e.target.value
        })
        if (data === "file") {
            const [file] = e.target.files;
            SetSupoortTicket({
                ...suportTicket,
                [e.target.name]: file,
            })
        }
    }

    // Check Errors
    const [errorData, setErrorData] = useState({});
    const [errShow, setErrShow] = useState(false);
    useEffect(() => {
        const error = validateRequiredFields(suportTicket, ["Name", "Email", "Phone", "Issue", "Description"]);
        setErrorData(error);
    }, [suportTicket]);

    const [isLoading, setIsLoading] = useState(false)

    // Create Support Ticket
    const handleSubmit = (event) => {
        event.preventDefault();
        setErrShow(true)
        const SuportTicketGen = new FormData()
        SuportTicketGen.append('Name', suportTicket.Name)
        SuportTicketGen.append('Email', suportTicket.Email)
        SuportTicketGen.append('Phone', suportTicket.Phone)
        SuportTicketGen.append('Issue', suportTicket.Issue)
        SuportTicketGen.append('Description', suportTicket.Description)
        SuportTicketGen.append('attachment', suportTicket.attachment)
        
        if (Object.keys(errorData).length === 0) {
            setIsLoading(true)
            axios.post(getCreateSupport, SuportTicketGen)
                .then((res) => {
                    toast.success(res.data.message, toastStyle);
                    handleOpenShowPopup();
                    setIsLoading(false)
                })
                .catch((err) => {
                    toast.error(err.response.data.message, toastStyle);
                    setIsLoading(false)
                });
        }
    };


    return (
        <div className='Suport'>

            <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom01">
                        <Form.Label>Name<span className='required'>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter Full Name" value={suportTicket.Name} onChange={(e) => handleChange(e)} name='Name' />
                        {errShow ? <p className='error-msg'>{errorData?.Name?.message}</p> : ""}
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom02">
                        <Form.Label>Email<span className='required'>*</span></Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={suportTicket.Email} onChange={(e) => handleChange(e)} name='Email' />
                        {errShow ? <p className='error-msg'>{errorData?.Email?.message}</p> : ""}
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom03">
                        <Form.Label>Phone Number<span className='required'>*</span></Form.Label>
                        <Form.Control type="number" placeholder="Enter Phone Number" value={suportTicket.Phone} onChange={(e) => handleChange(e)} name='Phone' />
                        {errShow ? <p className='error-msg'>{errorData?.Phone?.message}</p> : ""}
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom04">
                        <Form.Label>Subject<span className='required'>*</span></Form.Label>
                        <Form.Control type="text" placeholder='Enter Subject' value={suportTicket.Issue} onChange={(e) => handleChange(e)} name="Issue" />
                        {errShow ? <p className='error-msg'>{errorData?.Issue?.message}</p> : ""}
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="validationCustom04">
                        <Form.Label>Discription<span className='required'>*</span></Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder='Enter Discription' value={suportTicket.Description} onChange={(e) => handleChange(e)} name="Description" />
                        {errShow ? <p className='error-msg'>{errorData?.Description?.message}</p> : ""}
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>Attachment</Form.Label>
                        <Form.Control type="file" name="attachment" onChange={(e) => handleChange(e, "file")} className='novalidationhere' />
                    </Form.Group>
                </Row>

                <Button variant="primary" className='common-btn m-0 support-submit w-100 justify-content-center' disabled={isLoading} type="submit">
                    <span>Submit</span>
                </Button>
            </Form>

            <Modal open={showPopup} onClose={handleCloseShowPopup} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box className="quotation-view x-support" sx={style}>
                    <div className='quotationBody ex-lgbt-fr-support'>
                        <div className="quotationHeader extra-headerbtn">
                            <div className="quotationBtn x-support-btn">
                                <CloseIcon className='CloseIcon' onClick={handleCloseShowPopup} />
                            </div>
                        </div>
                        <div className="main-container">
                            <div className="check-container">
                                <div className="check-background">
                                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7 25L27.3077 44L58.5 7"
                                            stroke="white"
                                            strokeWidth="13"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className="check-shadow"></div>
                            </div>
                        </div>
                        <h1 className='succes-supoort-btn'>Success!</h1>
                        <p className='pera-support-btn'>Support ticket has been created successfully, <br />We will get back to you soon.</p>
                    </div>
                </Box>
            </Modal>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default Support