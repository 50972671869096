import React, { useEffect, useState } from 'react';
import './ContactUs.css';
import "../../Components/Header/Header.css"
import { NavLink, useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Support from '../../Components/Support/Support';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FeedBack from '../../Components/FeedBack/FeedBack';
import bgcoils from '../../Images/bgimg.png'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SiteFooter from '../../Components/Footer/SiteFooter';
import { AiOutlineLink } from 'react-icons/ai';

const ContactUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate()
  const [selectedForm, setSelectedForm] = useState('support');

  let title = '';
  if (selectedForm === 'support') {
    title = 'Get Technical Support';
  } else if (selectedForm === 'feedback') {
    title = 'How likely are you to recommend our service to your family or colleague';
  }

  return (
    <>

      <section className='Privacy-Policys buyNow'>
        <div className='inner-banner'>
          <div className="container">
            <h2>Contact</h2>
          </div>
        </div>
      </section>

      <div className='Support-page'>
        <div className="content">
          <div className='container'>
            <div className='custom-wrapper-quote'>
              <div className='inner-row-bdy'>
                <div className='about_blocks'>
                  <h1><span>C</span>OMMON <span>E</span>VENTS <span>P</span>LANNING <span>T</span>EAM
                  </h1>
                </div>
                <div className='box-details two'>

                  <ul className="elementor-icon-list-items">
                    {/* <li className="elementor-icon-list-item">
                      <a href="tel:+919326967938">
                        <span className="elementor-icon-list-icon">
                          <LocalPhoneRoundedIcon />
                        </span>
                        <span className="elementor-icon-list-text">+91-93269 67938</span>
                      </a>
                    </li> */}
                    <li className="elementor-icon-list-item">
                      <a href="mailto:commoneventsplanningteam@gmail.com">
                        <span className="elementor-icon-list-icon">
                          <MarkEmailReadRoundedIcon />
                        </span>
                        <span className="elementor-icon-list-text isEmail">commoneventsplanningteam@gmail.com</span>
                      </a>
                    </li>
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon">
                        <LocationOnIcon />
                      </span>
                      <span className="elementor-icon-list-text"><NavLink to="https://maps.app.goo.gl/cqRpAZUdkpGEBYMU7" target='_blank'>shop no. A/2 Shivganga residency , opposite dharti vidyalay , gopal chowk nava naroda 382345<AiOutlineLink /></NavLink></span>
                    </li>
                  </ul>

                </div>
              </div>
              <div className='userdashboardcnt'>
                <div id="box1" className="box show">
                  <div className='Support-title mb-4'>
                    <h1>How can we help?</h1>
                  </div>
                  <div className='cst-radiobtns-bd'>
                    <Support />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ContactUs;
